import * as React from 'react'
import { useState, useEffect } from 'react'

import ResearchBoxes from 'components/research/ResearchBoxes'

type CategorySortProps = {
  articles: ResearchNode[]
}

const CategorySort = ({ articles }: CategorySortProps) => {
  // generating list of unique categories
  let categories = articles.map((item) => item.Category)

  // generating category post count
  const postsPerCategory: { [key: string]: number } = {}
  for (const category of categories) {
    postsPerCategory[category] = postsPerCategory[category]
      ? postsPerCategory[category] + 1
      : 1
  }

  // generating set from array
  categories = ([] as string[]).concat
    .apply([], categories)
    .filter((item, index, array) => array.indexOf(item) === index)

  // STATE
  const [filteredPosts, setFilteredPosts] = useState(articles)

  const [currentCategory, setCurrentCategory] = useState('all')

  const blogPostIteration = 12 // number of posts to show each button press
  const [lengthBlogPosts, setLengthBlogPosts] = useState(articles.length)
  const [blogPostsVisible, setBlogPostsVisible] = useState(blogPostIteration)

  const [activeCategory, setActiveCategory] = useState(0)

  // SORTING LOGIC
  const changeCategory = (dataset: DOMStringMap) => {
    dataset.option && setCurrentCategory(dataset.option)
    dataset.activeIndex && setActiveCategory(parseInt(dataset.activeIndex))
  }

  // when category changes, filter posts again
  useEffect(() => {
    setFilteredPosts(
      currentCategory === 'all'
        ? articles
        : articles.filter((post) => post.Category === currentCategory)
    )
  }, [currentCategory])

  // SHOW MORE LOGIC
  // when filtered posts array changes, recalculate length of array, reset blog posts visible
  useEffect(() => {
    setLengthBlogPosts(filteredPosts.length)
    setBlogPostsVisible(blogPostIteration)
  }, [filteredPosts])

  const showMore = () => {
    setBlogPostsVisible(blogPostsVisible + blogPostIteration)
  }

  const renderShowMoreButton = () => {
    if (blogPostsVisible >= lengthBlogPosts) return null
    return (
      <button className="btn btn-lg btn-1" onClick={showMore}>
        Load more
      </button>
    )
  }

  const selectedStyles = 'font-semibold border-b'

  return (
    <div className="inner-container">
      <h3>Publications</h3>
      <div className="mt-10">
        <button
          data-option="all"
          data-active-index={0}
          onClick={(e) => changeCategory(e.currentTarget.dataset)}
          className="block lg:inline-block"
        >
          <span
            className={`cursor-pointer ${
              activeCategory == 0 ? selectedStyles : ''
            }`}
          >
            All Categories ({articles.length})
          </span>
        </button>
        {categories.map((category, index) => {
          return (
            <button
              data-option={category}
              data-active-index={index + 1}
              className="block lg:inline-block lg:ml-4"
              onClick={(e) => changeCategory(e.currentTarget.dataset)}
              key={index}
            >
              <span
                className={`cursor-pointer ${
                  activeCategory == index + 1 ? selectedStyles : ''
                }`}
              >
                {category} ({postsPerCategory[category]})
              </span>
            </button>
          )
        })}
      </div>
      <div className="mt-20">
        <ResearchBoxes articles={filteredPosts.slice(0, blogPostsVisible)} />
        {/* {renderShowLessButton()} */}
        <div className="mt-20 text-center">{renderShowMoreButton()}</div>
      </div>
    </div>
  )
}

export default CategorySort
