import * as React from 'react'

import ResearchBox from 'components/research/ResearchBox'
import { ResearchNode } from 'types'

type ResearchBoxesProps = {
  articles: ResearchNode[]
}

const ResearchBoxes = ({ articles }: ResearchBoxesProps) => {
  return (
    <div className="flex flex-row flex-wrap -m-10.5 max-w-full">
      {articles.map((item, index) => {
        return (
          <div className="w-full lg:w-1/3 p-10.5" key={index}>
            <ResearchBox article={item} index={index} />
          </div>
        )
      })}
    </div>
  )
}

export default ResearchBoxes
