import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useRef, useEffect } from 'react'
import { getImageDataByFilename } from '../utils/getImageDataByFilename'

type HeroProps = {
  title: string
  subTitle: string
  details?: string | JSX.Element
  image: string
  imageAlt?: string
  imageIsPhoto?: boolean
  editorial?: boolean
}

const Hero = ({
  subTitle,
  title,
  details,
  image,
  imageAlt,
  imageIsPhoto = false,
  editorial = true,
}: HeroProps) => {
  type HeroStyles = { paddingLeft?: string; height?: string }
  const [heroStyles, setHeroStyles] = React.useState<HeroStyles>({})

  const innerContainer = useRef<HTMLDivElement>(null)

  const [leftContainerPadding, setLeftContainerPadding] = React.useState(0)

  const handleResize = () => {
    if (null !== innerContainer.current) {
      setLeftContainerPadding(innerContainer.current.offsetLeft)
    }
  }

  // finding left padding for editorial layout
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    // event listener cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const styles: HeroStyles = {}
    editorial ? (styles.paddingLeft = `${leftContainerPadding}px`) : ''
    setHeroStyles(styles)
  }, [leftContainerPadding])

  return (
    <div className={`pb-20 pt-12.5`}>
      <div className="inner-container" ref={innerContainer} />
      <div
        className={`relative ${!editorial ? 'inner-container' : ''}`}
        style={heroStyles}
      >
        <div className={`h-full ${editorial && 'pl-5 pr-5 md:pr-0'}`}>
          <div className={`flex flex-row`}>
            <div className="w-full md:w-1/2 lg:py-7.5">
              <div className="flex flex-col justify-center h-full lg:pr-25">
                <div className="flex-none">
                  <p className="subtitle">{subTitle}</p>
                  <div
                    className={`mt-5 md:hidden ${
                      imageIsPhoto ? 'hero-aspect-ratio w-full' : ''
                    }`}
                  >
                    <GatsbyImage
                      image={getImageDataByFilename(image)}
                      alt={imageAlt ? imageAlt : title}
                      className={`object-contain w-full h-auto md:h-80 ${
                        imageIsPhoto ? 'object-cover h-full' : ''
                      }`}
                    />
                  </div>
                  <h1 className="mt-5 text-4xl leading-[3.125rem] sm:text-[2.875rem] md:text-[3.5rem] md:leading-[3.875rem]">
                    {title}
                  </h1>
                  {details && <p className="main-p">{details}</p>}
                </div>
              </div>
            </div>
            <div className="flex-col justify-center hidden md:flex md:w-1/2 md:pl-5 lg:pl-0">
              {image && (
                <div
                  className={`flex justify-center ${
                    imageIsPhoto ? 'hero-aspect-ratio w-full' : ''
                  }`}
                >
                  <GatsbyImage
                    image={getImageDataByFilename(image)}
                    alt={imageAlt ? imageAlt : title}
                    className={`w-full h-full max-w-md ${
                      imageIsPhoto ? 'object-cover h-full' : 'object-contain'
                    }`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
