/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Hero from 'components/Hero'
import CategorySort from 'components/research/ResearchCategories'

import { GatsbyImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../utils/getImageDataByFilename'
import { ResearchNode } from 'types'
import Seo from '../components/Seo'

const domainsOfResearch: {
  image: string
  title: string
  details: string | JSX.Element
}[] = [
  {
    image: 'MEL_0219_crop.jpg',
    title: 'Photonic architectures',
    details: (
      <>
        <p>
          Building a quantum computer is a dance between hardware and
          architecture. While hardware research tackles difficult problems of
          implementation and reducing imperfections, architecture efforts must
          complement them with a viable way forward: designs not just for a
          scalable quantum computer, but for a feasible device aware of the
          realities on the ground.
        </p>
        <p className="mt-7.5">
          Xanadu Research has provided a blueprint for a fault-tolerant quantum
          computer consisting of four components: state preparation,
          multiplexing, stitching, and QPU (quantum processing unit). While the
          proposal already facilitates a realistic quantum computer — the
          architecture is fast, modular, networkable, and mass-manufacturable —
          there is much more work to be done on all of its components.
        </p>
        <p className="mt-7.5">
          The key for us is to understand and simulate the impact of various
          sources of noise, to mitigate and correct for errors caused by these
          physical imperfections, and to ultimately reduce the overheads for
          running a quantum computation. We are looking not just for ways to
          improve the quality and probability of the photonic qubits we intend
          to produce, but also for alternative quantum error correction
          approaches — photonic architectures are amenable to many — to be able
          to handle any errors that do arise.
        </p>
      </>
    ),
  },
  {
    image: 'MEL_0150.jpg',
    title: 'Quantum programming',
    details: (
      <>
        <p>
          There are many challenging questions about how to best program a
          quantum computer. At Xanadu Research we are interested in a broad
          range of them, such as designing intuitive user interfaces, developing
          flexible compilation pipelines, building fast simulators, and setting
          up powerful remote job management systems.
        </p>
        <p className="mt-7.5">
          Our work is strongly influenced by the paradigm of differentiable
          quantum computing. Differentiable computing — the engine of modern-day
          machine learning — uses optimization tools like automatic
          differentiation to find the algorithm that provides the best solution
          to a problem. This approach requires us to rethink existing ideas in
          quantum computing, as well as developing new techniques across the
          stack. The fruits of our work are integrated into a number of
          open-source libraries, including our flagship platform PennyLane, to
          provide convenient access to the latest cutting-edge developments in
          this field.
        </p>
      </>
    ),
  },
  {
    image: 'MEL_0034.jpg',
    title: 'Quantum algorithms',
    details: (
      <>
        <p>
          If we want quantum computers to reach their full potential, we have to
          narrow down the applications and algorithms that can have an impact.
          We must find the points where theoretical constructions, experiments,
          and practical utility meet, e.g., in areas like quantum machine
          learning, quantum chemistry, and quantum algorithms.
        </p>
        <p className="mt-7.5">
          Sometimes this means discarding popular ideas in research, going back
          to the drawing board, or rephrasing our questions. At other times, we
          need time to understand a problem very deeply, or simply run with an
          idea and put it to the test in large-scale implementations that push
          the boundaries of whats possible. At Xanadu Research we believe that
          this combination of critical thinking, careful theoretical
          understanding, and practical tool-building is what it takes to make
          quantum computers truly useful.
        </p>
      </>
    ),
  },
]

type ResearchPageProps = {
  data: {
    allResearchJson: { nodes: ResearchNode[] }
  }
}

const ResearchPage = ({ data }: ResearchPageProps) => {
  const [domainExpanded, setDomainExpanded] = React.useState<null | number>(
    null
  )

  const research = data.allResearchJson.nodes

  return (
    <Layout>
      <div className="overflow-hidden">
        {/* HERO */}
        <Hero
          subTitle="// Xanadu Research"
          title="We want quantum computers to be useful"
          details="Our research aims to identify key ideas and translate them into tools that anyone can access."
          image="RHG_matching.png"
          editorial={false}
        />

        {/* DOMAINS */}
        <div className="standard-page-section bg-grey-300">
          <div className="inner-container">
            <div>
              <p className="subtitle">// Focus</p>
              <h2>Xanadu Research focuses on three domains</h2>
            </div>
            <div className="flex flex-row flex-wrap mt-7.5">
              {domainsOfResearch.map((item, i) => {
                return (
                  <div className={`w-full p-3 md:w-1/3`} key={i}>
                    <div className="w-full">
                      <GatsbyImage
                        className="object-cover"
                        image={getImageDataByFilename(item.image)}
                        alt=""
                      />
                    </div>
                    <h6 className="mt-2.5 text-center lg:mt-4">{item.title}</h6>
                    <div className="mt-2.5 text-center">
                      <button
                        className="font-semibold border-b-2 swipe-link swipe-link-2 border-primary-600"
                        onClick={() =>
                          setDomainExpanded(i === domainExpanded ? null : i)
                        }
                      >
                        <span data-hover="Learn more">Learn more</span>
                      </button>
                    </div>
                    <div
                      className={`block md:hidden transition-all ease-linear duration-500 ${
                        domainExpanded === i
                          ? 'mt-8 opacity-100 visible max-h-500'
                          : 'invisible opacity-0 max-h-0'
                      }`}
                    >
                      {domainExpanded === i && (
                        <span>
                          {domainsOfResearch[domainExpanded]['details']}
                        </span>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            {/* DETAILS */}
            <div
              className={`hidden md:block transition-all ease-linear duration-500 ${
                domainExpanded !== null
                  ? 'mt-8 opacity-100 visible max-h-96'
                  : 'invisible opacity-0 max-h-0'
              }`}
            >
              {domainExpanded !== null && (
                <span>{domainsOfResearch[domainExpanded]['details']}</span>
              )}
            </div>
          </div>
        </div>

        {/* CATEGORIES */}
        <div className="standard-page-section">
          <CategorySort articles={research} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    # allResearchJson(sort: { fields: ID, order: DESC }) {
    allResearchJson(sort: { order: DESC, fields: Date }) {
      nodes {
        id
        Title
        Date
        ID
        URL
        Category
        featured
      }
    }
  }
`

export default ResearchPage

export const Head = () => {
  return (
    <Seo
      title="Research"
      description="Read about Xanadu's latest milestones"
      image="RHG_matching.png"
    />
  )
}
