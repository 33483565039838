import { useStaticQuery, graphql } from 'gatsby'

// Takes in an image filename string ("my-image.png") and returns the GatsbyImageData for that image.
export const getImageDataByFilename = (filename: string) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg|jpeg|png|webp)/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  // Find the image node that matches the provided filename
  const imageNode = data.allFile.edges.find(
    (edge: { node: { relativePath: string } }) =>
      edge.node.relativePath.includes(filename)
  )
  if (!imageNode) {
    return null
  }
  return imageNode.node.childImageSharp.gatsbyImageData
}
