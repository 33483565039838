import * as React from 'react'
import { ResearchNode } from 'types'

export type ResearchBoxProps = {
  article: ResearchNode
  index: number
}

const ResearchBox = ({ article }: ResearchBoxProps) => {
  const { Title, URL, Date } = article
  return (
    <>
      <h5 className="h5-sm">
        <a
          href={URL}
          target="_blank"
          aria-label={`${Title} ${Date} (opens in new tab)`}
          rel="noopener noreferrer"
        >
          {Title}
        </a>
      </h5>
      <a
        href={URL}
        target="_blank"
        className="block mt-5 font-semibold border-b-2 swipe-link swipe-link-2 border-primary-600"
        aria-label={`${Title} ${Date} (opens in new tab)`}
        rel="noopener noreferrer"
      >
        <span data-hover={Date}>{Date}</span>
      </a>
    </>
  )
}

export default ResearchBox
